import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/packages/selgros24pl/frontend/components/commercetools-ui/organisms/maintenance/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/account/activation/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/account/details/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/account/login/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/account/orders/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/account/registration/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/account/reset-password/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/cart/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/category-slider/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/checkout/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/content-slider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/content/hero-slider/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/content/hero-tile-slider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/content/hero/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/content/tile/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/content/tiles-group/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/footer-account/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/footer/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/header-account/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/header/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/markdown/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/products/advanced-slider/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/products/details/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/products/other-products/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/products/product-list/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/products/similar-products/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/products/slider/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/showcase/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/sitemap/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/spacer/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/frontastic/tastics/thank-you/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/selgros24pl/frontend/node_modules/react-loading-skeleton/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/opt/build/repo/packages/selgros24pl/frontend/providers/index.tsx");
