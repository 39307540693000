import { FC } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import CartItemsList from './cart-items-list';
import EmptyCart from './empty-cart';
import InfoMessage from '../../info-message';
import { CartContentProps } from '../types';

const CartContent: FC<CartContentProps> = ({ className, infoMessages, maxCartValueExceededMessage, ...props }) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  const { isEmpty, totalItems, hasOutOfStockItems, isValid } = useCart();

  return (
    <div className={className}>
      <div className="flex items-end gap-12">
        <h3 className="text-24 font-bold leading-[30px] text-neutral-4 lg:text-32 lg:leading-[40px]">
          {formatCartMessage({ id: 'cart', defaultMessage: 'Cart' })}
        </h3>
        {!isEmpty && (
          <span className="text-14 font-normal leading-[20px] text-neutral-3 lg:text-16 lg:leading-[26px]">
            {totalItems}
          </span>
        )}
      </div>
      {!isEmpty ? (
        <>
          {!isValid && maxCartValueExceededMessage && (
            <div className="mb-8 mt-16 flex flex-col lg:mb-12 lg:mt-20">
              <InfoMessage
                headline={maxCartValueExceededMessage[0].headline}
                text={maxCartValueExceededMessage[0].text}
                icon="info"
                iconColor="error"
                additionalClassName="bg-status-danger-bg"
              />
            </div>
          )}

          {hasOutOfStockItems && (
            <div className="mb-8 mt-16 flex flex-col lg:mb-12 lg:mt-20">
              <InfoMessage
                headline={formatCartMessage({
                  id: 'hasOutOfStockItems.headline',
                  defaultMessage: 'Please remove products that are not available to continue checkout',
                })}
                text={formatCartMessage({
                  id: 'hasOutOfStockItems.text',
                  defaultMessage:
                    'Some items in the cart are currently not available. Please remove them from the cart to proceed with the checkout',
                })}
                icon="info"
                iconColor="error"
                additionalClassName="bg-status-danger-bg"
              />
            </div>
          )}

          <CartItemsList />

          {infoMessages && (
            <div className="mt-24 flex flex-col gap-12 lg:mt-32">
              {infoMessages.map((infoMessage, index) => (
                <InfoMessage key={index} {...infoMessage} />
              ))}
            </div>
          )}
        </>
      ) : (
        <EmptyCart {...props} />
      )}
    </div>
  );
};

export default CartContent;
