'use client';

import { Product } from 'shared/types/product/Product';
import AdvancedProductSlider, {
  AdvancedProductSliderAs,
  AdvancedProductSliderProps,
} from 'components/commercetools-ui/organisms/product/advanced-product-slider';
import { HOME_PAGE } from 'helpers/constants/staticPages';
import { DataSource } from 'types/datasource';
import { TasticProps } from 'frontastic/tastics/types';

function AdvancedProductSliderTastic({
  data,
  page,
}: TasticProps<DataSource<{ items: Product[] }> & AdvancedProductSliderProps>) {
  if (!data?.data?.dataSource?.items) return <p>No products found.</p>;

  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mobile, tablet, desktop, data: products, ...props } = data;

  const getAs = (): AdvancedProductSliderAs => {
    const pageIdentifier = page.isDynamic ? page.pageFolderType : page.name;

    switch (pageIdentifier) {
      case HOME_PAGE:
        return { advancedProductSlider: { title: 'h2' } };
      default:
        return undefined;
    }
  };

  return <AdvancedProductSlider {...props} products={data.data.dataSource.items} as={getAs()} />;
}

export default AdvancedProductSliderTastic;
