import React, { ReactNode, useMemo, useEffect } from 'react';
import { useParams, useRouter } from 'next/navigation';
import Container from 'components/commercetools-ui/atoms/container';
import triggerToast from 'components/commercetools-ui/atoms/toaster/utils/triggerToast';
import useCloseFlyouts from 'helpers/hooks/useCloseFlyouts';
import { useFormat } from 'helpers/hooks/useFormat';
import usePagePathWithLocale from 'helpers/hooks/usePagePathWithLocale';
import { useAccount, useCart } from 'frontastic';
import CartContent from './components/cart-content';
import { CartProps } from './types';
import OrderOverview from '../order-overview';
import CheckoutButton from '../order-overview/components/checkout-button';
import useCostsData from '../order-payment-section/hooks/useCostsData';

const Cart: React.FC<CartProps> = ({
  infoMessages,
  paymentMethods,
  modalLogin,
  maxCartValueExceededMessage,
  ...props
}) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const title = formatCartMessage({ id: 'order.summary', defaultMessage: 'Order Summary' });

  // For <CartContent> and <CheckoutButton>
  const closeFlyouts = useCloseFlyouts();
  const { isEmpty, hasOutOfStockItems, isValid, validateCart, data } = useCart();
  const { loggedIn } = useAccount();
  const { checkoutPagePath, cartPagePath } = usePagePathWithLocale();
  const router = useRouter();

  const lineItemsOverLimit_genericError = formatCartMessage({
    id: 'lineItemsOverLimit.error.generic',
    defaultMessage: 'The maximum number of products in the order has been reached.',
  });
  const lineItemsOverLimit_individualErrorPart1 = formatCartMessage({
    id: 'lineItemsOverLimit.error.individual.part1',
    defaultMessage: 'Maximum quantity for product',
  });
  const lineItemsOverLimit_individualErrorPart2 = formatCartMessage({
    id: 'lineItemsOverLimit.error.individual.part2',
    defaultMessage: 'in the order was reached.',
  });

  useEffect(() => {
    if (data?.validations?.lineItemsOverTheOrderLimit?.isValid == false) {
      if (data?.validations?.lineItemsOverTheOrderLimit?.validationType == 'generic') {
        triggerToast({ message: lineItemsOverLimit_genericError }, { id: '1' });
      }

      if (data?.validations?.lineItemsOverTheOrderLimit?.validationType == 'individual') {
        data?.validations?.lineItemsOverTheOrderLimit.lineItems.forEach((sku) => {
          triggerToast(
            {
              message: `${lineItemsOverLimit_individualErrorPart1} ${
                data?.lineItems?.find((lineItem) => lineItem.variant?.sku === sku)?.name
              } ${lineItemsOverLimit_individualErrorPart2}`,
            },
            { id: sku },
          );
        });
      }
    }
  }, [
    data?.lineItems,
    data,
    lineItemsOverLimit_genericError,
    lineItemsOverLimit_individualErrorPart1,
    lineItemsOverLimit_individualErrorPart2,
  ]);

  // For <CtaButton>
  const ctaButton: ReactNode = useMemo(() => {
    const ctaButtonOnClickAction = async () => {
      const validatedCart = await validateCart();
      if (validatedCart.validations?.lineItemsOverTheOrderLimit?.isValid) {
        closeFlyouts();
        router.push(checkoutPagePath);
      } else {
        router.push(cartPagePath);
      }
    };

    return (
      <CheckoutButton
        useButtonComponent
        buttonComponentProps={{
          variant: 'primary',
          className: 'w-full',
          'data-test': 'cart-page-order-summary-cta-button',
        }}
        text={formatCartMessage({ id: 'checkout.go', defaultMessage: 'Go to checkout' })}
        onClick={ctaButtonOnClickAction}
        disabled={isEmpty || hasOutOfStockItems || !loggedIn || !isValid}
      />
    );
  }, [
    formatCartMessage,
    closeFlyouts,
    checkoutPagePath,
    isEmpty,
    hasOutOfStockItems,
    loggedIn,
    isValid,
    validateCart,
    cartPagePath,
    router,
  ]);

  // For <Costs>
  const { locale } = useParams();
  const { loading, costsToRender, total } = useCostsData({ dataReference: 'cart' });

  const costsPropsFromParentPage = {
    locale,
    loading,
    costsToRender,
    total,
    freeShipping: data?.freeShipping,
  };

  const modalLoginContent = modalLogin?.[0];

  return (
    <div className="relative bg-background">
      <Container>
        <div className="flex flex-col px-20 pb-40 pt-16 lg:flex-row lg:items-start lg:gap-12 lg:px-47 lg:py-40">
          <CartContent
            className="lg:w-[70%]"
            infoMessages={infoMessages}
            maxCartValueExceededMessage={maxCartValueExceededMessage}
            {...props}
          />
          <OrderOverview
            costsPropsFromParentPage={costsPropsFromParentPage}
            ctaButton={ctaButton}
            page="cart"
            paymentMethods={paymentMethods}
            title={title}
            // Optional props
            showLogin={!loggedIn}
            modalLogin={modalLoginContent}
          />
        </div>
      </Container>
    </div>
  );
};

export default Cart;
