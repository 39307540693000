'use client';

import React from 'react';
import { useSearchParams } from 'next/navigation';
import ResetPassword, {
  ResetPasswordContent,
} from 'components/commercetools-ui/organisms/authentication/reset-password';
import usePagePathWithLocale from 'helpers/hooks/usePagePathWithLocale';
import Redirect from 'helpers/redirect';
import { TasticProps } from 'frontastic/tastics/types';

const ResetPasswordTastic = ({ data }: TasticProps<ResetPasswordContent>) => {
  //next/navigation
  const searchParams = useSearchParams();
  const { loginPagePath } = usePagePathWithLocale();

  //reset password token
  const token = searchParams.get('token');

  if (!token) return <Redirect target={loginPagePath} />;

  return <ResetPassword token={token} studio={data} />;
};

export default ResetPasswordTastic;
