import React from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { Reference } from 'types/reference';
import Image from 'frontastic/lib/image';
import { BottomLinkFooter, Time } from '..';

export interface FooterLink {
  name: string;
  reference: Reference;
}

export interface Props {
  header?: string;
  links?: FooterLink[];
  className?: string;
  phoneNumber?: string;
  availableDays?: Time;
  availableHours?: Time;
  paymentMethodsLinks?: BottomLinkFooter[];
}

const Column: React.FC<Props> = ({
  header,
  links,
  className,
  phoneNumber,
  availableDays,
  availableHours,
  paymentMethodsLinks,
}) => {
  return (
    <div className={className}>
      {header && <Typography className="self-start text-16 font-bold leading-5 text-neutral-4">{header}</Typography>}
      <div className="">
        {phoneNumber && availableDays && availableHours && (
          <div className="mb-20 flex flex-col flex-wrap gap-4 md:mb-16">
            <p className="self-start text-20 font-bold text-base-accent-1 md:text-24 md:leading-[30px]">
              <a href={`tel:${phoneNumber.split(' ').join('')}`}>{phoneNumber} </a>
            </p>
            <p className="self-start text-14 leading-[17.5px] text-neutral-4">
              {`${availableDays.startTime} - ${availableDays.endTime}: `}
              <span className="font-bold">
                {availableHours.startTime} - {availableHours.endTime}
              </span>
            </p>
          </div>
        )}
        <ul
          role="list"
          className={`flex flex-wrap ${
            paymentMethodsLinks ? 'flex-row gap-4' : 'flex-col'
          } items-start gap-y-16 self-start max-md:gap-y-20`}
        >
          {links
            ? links.map((item, i) => (
                <li key={i}>
                  <Link variant="primary" className="!text-14 !leading-[17.5px] !text-neutral-4" link={item.reference}>
                    <Typography as="fragment">{item.name}</Typography>
                  </Link>
                </li>
              ))
            : paymentMethodsLinks?.map((item, i) => (
                <Link key={i} link={item.reference}>
                  <li
                    key={JSON.stringify(item.reference)}
                    className="flex h-44 w-88 items-center justify-center gap-67 border border-base-accent-2 bg-neutral-5 p-8"
                  >
                    <Image {...item.logo} />
                  </li>
                </Link>
              ))}
        </ul>
      </div>
    </div>
  );
};

export default Column;
