import { useCallback } from 'react';
import { Product } from 'shared/types/product/Product';
import { VIEW_ITEM } from 'helpers/constants/events';
import { productToGoogleAnalytics4Item } from 'helpers/utils/mapGoogleAnalytics';

const useTrack = () => {
  const trackViewItem = useCallback(async (product: Product, position: number) => {
    const googleAnalytics4Item = productToGoogleAnalytics4Item(product);
    const currency = product?.variants?.[0]?.price?.currencyCode ?? 'PLN';
    const value = googleAnalytics4Item.price;
    const netValue = googleAnalytics4Item.price_net;

    gtag('event', VIEW_ITEM, {
      currency,
      value,
      value_net: netValue,
      items: [{ ...googleAnalytics4Item, quantity: 1, index: position }],
    });
  }, []);

  return { trackViewItem };
};

export default useTrack;
