import { useMemo } from 'react';
import { useCart } from 'frontastic';
import CartItem from './cart-item';
import useTrack from '../useTrack';

const CartItemsList = () => {
  const { data, hasOutOfStockItems } = useCart();

  const lineItems = useMemo(() => {
    return (data?.lineItems ?? []).filter((lineItem) => lineItem.variant?.isOnStock);
  }, [data?.lineItems]);

  const outOfStockLineItems = useMemo(() => {
    return (data?.lineItems ?? []).filter((lineItem) => !lineItem.variant?.isOnStock);
  }, [data?.lineItems]);

  const discountCodes = data?.discountCodes;

  const { ref } = useTrack({ lineItems });

  return (
    <div ref={ref}>
      {hasOutOfStockItems && (
        <div className="mt-12 flex flex-col gap-12">
          {outOfStockLineItems.map((lineItem) => (
            <div key={lineItem.lineItemId}>
              <CartItem item={lineItem} discountCodes={discountCodes} />
            </div>
          ))}
        </div>
      )}
      <div className={`${hasOutOfStockItems ? 'mt-32' : 'mt-16 lg:mt-20'} flex flex-col gap-12`}>
        {lineItems.map((lineItem) => (
          <div key={lineItem.lineItemId}>
            <CartItem item={lineItem} discountCodes={discountCodes} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CartItemsList;
