'use client';

import React, { useMemo } from 'react';
import { useSearchParams } from 'next/navigation';
import Container from 'components/commercetools-ui/atoms/container';
import Login, { LoginContent } from 'components/commercetools-ui/organisms/authentication/login';
import usePagePathWithLocale from 'helpers/hooks/usePagePathWithLocale';
import Redirect from 'helpers/redirect';
import { useAccount } from 'frontastic/hooks';
import { TasticProps } from 'frontastic/tastics/types';

const AccountLoginTastic = ({ data }: TasticProps<LoginContent>) => {
  const searchParams = useSearchParams();
  const { homePagePath } = usePagePathWithLocale();

  const lvp = searchParams.get('lvp');

  //redirection link after user is logged in
  //Example: '/login?lvp=cart' will redirect to cart page
  const redirectLink = useMemo(() => {
    const lastVisitedPage = lvp ? `/${lvp}` : homePagePath;
    return lastVisitedPage;
  }, [homePagePath, lvp]);

  const { loggedIn } = useAccount();

  if (loggedIn) return <Redirect target={redirectLink} />;

  return (
    <div className="bg-background py-32 lg:py-40">
      <Container>
        <Login studio={data} />
      </Container>
    </div>
  );
};

export default AccountLoginTastic;
