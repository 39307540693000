import React, { FC } from 'react';
import { Product } from 'shared/types/product/Product';
import { SwiperOptions } from 'swiper/types';
import Slider from 'components/commercetools-ui/atoms/slider';
import Typography from 'components/commercetools-ui/atoms/typography';
import { TypographyProps } from 'components/commercetools-ui/atoms/typography/types';
import Wrapper from 'components/HOC/wrapper';
import { lg, md, sm, xl } from 'helpers/utils/screensizes';
import useTrack from './useTrack';
import ProductTile from '../product-tile';

export interface ProductSliderProps extends Partial<SwiperOptions> {
  products: Product[];
  title?: string;
  slidesPerView?: number;
  onProductClick?: (product: Product) => void;
  variant?: 'normal' | 'cart' | 'advanced';
  as?: ProductSliderAs;
}

export type ProductSliderAs = { ['productSlider']: { title: TypographyProps['as'] } } | undefined;

const ProductSlider: FC<ProductSliderProps> = ({ products, title, onProductClick, as, variant, ...props }) => {
  const { trackViewItem } = useTrack();

  return (
    <Wrapper
      className={`${
        variant === 'advanced' ? 'bg-background' : 'bg-neutral-5 md:bg-background'
      } min-h-[400px] px-16 pb-24 md:px-24 lg:min-h-[575px] lg:px-0`}
      background="background"
      clearDefaultStyles={true}
    >
      <div className="mx-auto max-w-[1538px]">
        {title ? (
          <div className="px-0 pt-12 lg:px-48">
            <Typography
              className="text-24 font-bold leading-[30px] text-neutral-4 md:text-32 md:leading-[40px]"
              as={as ? as.productSlider.title : 'h3'}
            >
              {title}
            </Typography>
          </div>
        ) : null}
        <div className={variant === 'advanced' ? 'mt-0 sm:-mt-25 md:-mt-205' : 'mt-20 md:mt-28'}>
          <div className="relative mt-6 w-full px-0 lg:px-48">
            <Slider
              variation={variant === 'advanced' ? 'product-advanced-slider' : undefined}
              className="grid bg-white"
              slidesPerView={1.4}
              slidesPerGroup={1}
              middleNavigation
              allowTouchMove
              spaceBetween={8}
              breakpoints={{
                [sm]: {
                  slidesPerView: 1.99,
                  slidesPerGroup: 2,
                  spaceBetween: 0,
                },
                [640]: {
                  slidesPerView: 2.5,
                  slidesPerGroup: 2,
                  spaceBetween: 0,
                },
                [md]: {
                  slidesPerView: 2.99,
                  slidesPerGroup: 3,
                  spaceBetween: 0,
                },
                [lg]: {
                  slidesPerView: 3.99,
                  slidesPerGroup: 4,
                  spaceBetween: 0,
                },
                [xl]: {
                  slidesPerView: 4.99,
                  slidesPerGroup: 5,
                  spaceBetween: 0,
                },
              }}
              {...props}
            >
              {products.map((product, index) => (
                <ProductTile
                  key={product.productId}
                  product={product}
                  onClick={() => {
                    trackViewItem(product, index + 1);
                    onProductClick?.(product);
                  }}
                  className="border-r border-neutral-2 bg-white max-sm:border-0 max-sm:p-12 sm:mt-24"
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ProductSlider;
